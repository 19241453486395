<template>
  <div>
    <v-row justify="center" no-gutters>
      <h1 v-bind:class="mobile ? 'mainTitleMobile' : 'mainTitle'">{{ $t('mainTitle') }}</h1>
    </v-row>
    <v-row justify="center" class="missionsMainContent" v-if="!mobile" no-gutters>
      <v-col cols="5" class="leftSection">
        <h3 class="subtitle">{{ $t('subtitle1') }}</h3> <br>
        <div class="textSectionLeft">
          {{ $t('section1text1') }} <br> <br>
          {{ $t('section1text2') }} <br> <br>
          {{ $t('section1text3') }}
          <v-img
              class="missionImgLeft"
              :src="require('@/assets/vitaeImg/section1.png')"
              contain
              height="175"
          />
          <br>
          <br>
          <br>
          <br>
          <br v-if="$i18n.locale !== 'fr'">
        </div>
        <h3 class="subtitle">{{ $t('subtitle3') }}</h3> <br>
        <div class="textSectionLeft">
          {{ $t('section3text1') }} <br> <br>
          {{ $t('section3text2') }}
        </div>
      </v-col>
      <v-col cols="2">
        <v-img
            :src="require('@/assets/picto/separator2.svg')"
            contain
            height="850"
        />
      </v-col>
      <v-col cols="5">
        <v-img
            class="missionImgRight"
            :src="require('@/assets/vitaeImg/section5.png')"
            contain
            height="180"
        />
        <br>
        <br>
        <br>
        <br>
        <h3 class="subtitle">{{ $t('subtitle2') }}</h3> <br>
        <div class="textSectionRight">
          {{ $t('section2text1') }} <br> <br>
          {{ $t('section2text2') }} <br> <br>
          {{ $t('section2text3') }} <br> <br>
          {{ $t('section2text4') }}
        </div>
        <br>
        <v-img
            class="missionImgRight"
            :src="require('@/assets/vitaeImg/section13.png')"
            contain
            height="180"
        />
      </v-col>
    </v-row>
    <v-row justify="center" class="missionsMainContentMobile" no-gutters v-if="mobile">
      <v-col cols="2">
        <v-img
            :src="require('@/assets/picto/separator2.svg')"
            contain
            height="1000"
        />
      </v-col>
      <v-col cols="10">
        <h3 class="subtitle">{{ $t('subtitle1') }}</h3> <br>
        <div class="">
          {{ $t('section1text1') }} <br> <br>
          {{ $t('section1text2') }} <br> <br>
          {{ $t('section1text3') }} <br>
          <v-img
              class="imgMobile"
              :src="require('@/assets/vitaeImg/section5.png')"
              contain
              height="100"
          />
        </div>
        <br>
        <h3 class="subtitle">{{ $t('subtitle2') }}</h3> <br>
        <div class="">
          {{ $t('section2text1') }} <br> <br>
          {{ $t('section2text2') }} <br> <br>
          {{ $t('section2text3') }} <br> <br>
          {{ $t('section2text4') }} <br> <br>
          <v-img
              class="imgMobile"
              :src="require('@/assets/vitaeImg/section5.png')"
              contain
              height="100"
          />
        </div>
        <h3 class="subtitle">{{ $t('subtitle3') }}</h3> <br>
        <div class="">
          {{ $t('section3text1') }} <br> <br>
          {{ $t('section3text2') }} <br> <br>
          <v-img
              class="imgMobile"
              :src="require('@/assets/vitaeImg/section13.png')"
              contain
              height="100"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['mobile'],
  name: 'MissionsComp',
  data: () => ({

  }),
}
</script>

<i18n>
{
  "en": {
    "mainTitle": "Our missions",
    "subtitle1": "To raise awareness",
    "section1text1": "The population respects the recommendations and benefits of a healthy lifestyle.",
    "section1text2": "Sports professionals adapting practices and evaluation criteria in line with the specificities of each population.",
    "section1text3": "Health professionals have the tools to prescribe and direct their patient to appropriate structures.",
    "subtitle2": "Develop",
    "section2text1": "Physical and sporting activity suitable for all.",
    "section2text2": "APA as a vehicle for primary, secondary and tertiary prevention care.",
    "section2text3": "APA as a non-drug therapy.",
    "section2text4": "Innovative subsidized actions.",
    "subtitle3": "To accompany",
    "section3text1": "People wishing to practice an adapted physical and sporting activity in groups, individually, face-to-face or remotely.",
    "section3text2": "Health, medical and social, business and community establishments to develop suitable physical activity and sport health well-being for their beneficiaries."
  },
  "fr": {
    "mainTitle": "Nos missions",
    "subtitle1": "Sensibiliser",
    "section1text1": "La population aux respects des recommandations et aux bienfaits d'une bonne hygiène de vie.",
    "section1text2": "Les professionnels du sport à l'adaptation des pratiques et aux critères d'évaluation en lien avec les spécificités de chaque population.",
    "section1text3": "Les professionnels de la santé aux outils pour prescrire et orienter leur(s) patient(s) vers des structures adaptées.",
    "subtitle2": "Développer",
    "section2text1": "L'activité physique et sportive adaptée pour tous.",
    "section2text2": "L'APA comme vecteur de soin en prévention primaire, secondaire et tertiaire.",
    "section2text3": "L'APA comme thérapie non-médicamenteuse.",
    "section2text4": "Des actions subventionnées innovantes.",
    "subtitle3": "Accompagner",
    "section3text1": "Les personnes souhaitant pratiquer une activité physique et sportive adaptée en groupe, en individuel, en présentiel ou à distance.",
    "section3text2": "Les établissements de santé, médico-sociaux, entreprise et collectivité à développer l'activité physique adpatée et le sport santé bien être pour leurs bénéficiaires."
  }
}
</i18n>

<style scoped>

.leftSection {
  text-align: right;
}

.subtitle {
  font-family: Comfortaa, sans-serif;
  color: #57939E;
}

.textSectionLeft {
  padding-left: 250px;
  color: #808080;
}

.textSectionRight {
  padding-right: 250px;
  color: #808080;
}
@media screen and (min-width: 1500px){
  .missionImgLeft {
    margin-top: 75px;
    margin-left: 280px;
  }

  .missionImgRight {
    margin-top: 50px;
    margin-right: 550px;
  }

}

@media screen and (max-width: 1500px){
  .missionImgLeft {
    margin-top: 75px;
    margin-left: 160px;
  }

  .missionImgRight {
    margin-top: 50px;
    margin-right: 410px;
  }

}

.missionsMainContent {
  padding-bottom: 50px;
}

.missionsMainContentMobile {
  color: #808080;
  font-size: 14px;
  padding-bottom: 50px;
  padding-right: 15px;
}

.imgMobile {
  margin-left: 100px;
}

</style>
