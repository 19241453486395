<template>
  <div>
    <MissionsComp v-bind:mobile="mobile" />
  </div>
</template>

<script>
import MissionsComp from '@/components/desktop/vitaeSportSante/MissionsComp'

  export default {
    name: 'Missions',
    props: ['mobile'],
    components: {
      MissionsComp
    },
  }
</script>
